import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _89ae6368 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _f435b792 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _7a3e8bbe = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _439cf002 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _fdaa9222 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _684e2a3d = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _c49c6832 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _71b064e0 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _7b5d2438 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _343b5090 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _fab6c5e8 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _f18d1380 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _4fab39a2 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _c6a8cf20 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _41952756 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _c2b2f1b4 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _89ae6368,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _f435b792,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _7a3e8bbe,
    name: "callback"
  }, {
    path: "/downloads",
    component: _439cf002,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _fdaa9222,
    name: "guests-details"
  }, {
    path: "/login",
    component: _684e2a3d,
    name: "login"
  }, {
    path: "/maintenance",
    component: _c49c6832,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _71b064e0,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _7b5d2438,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _343b5090,
    name: "search-booking"
  }, {
    path: "/downloads/ticket",
    component: _fab6c5e8,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _f18d1380,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _4fab39a2,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _c6a8cf20,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _41952756,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _c2b2f1b4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
