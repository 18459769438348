
import { defineComponent, PropType } from 'vue';
import { PortalTarget } from 'portal-vue';

import type {
  Policy,
} from '@white-label-types/parking-booking';
import type { Item as ReceiptItem } from '@white-label-types/parking-checkout';

import BTicket from '../b-ticket/b-ticket.vue';

import BTicketAdditionalInfo from '../b-ticket-additional-info/b-ticket-additional-info.vue';

export default defineComponent({
  components: {
    BTicketAdditionalInfo,
    BTicket,
    PortalTarget,
  },

  props: {
    isParkingProduct: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array as PropType<ReceiptItem[]>,
      required: true,
    },

    orderReference: {
      type: String,
      default: '',
    },

    subItems: {
      type: Array,
      default() {
        return [];
      },
    },

    modifications: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    cancellationPolicies(): Policy[] {
      if (this.items[0]?.cancellation_protection) {
        return this.items[0].cancellation_protection.cancellation_policies;
      }
      return this.items[0]?.inventory_option?.cancellation_policies;
    },
    sortedOrderItems(): ReceiptItem[] {
      const items = [ ...this.items ];
      return items.sort((a, b) => {
        const productCodeA = a.product_code;
        const productCodeB = b.product_code;

        const entryDateA = a.order_criteria?.[productCodeA]?.date1;
        const entryDateB = b.order_criteria?.[productCodeB]?.date1;

        const entryTimeA = a.order_criteria?.[productCodeA]?.time1;
        const entryTimeB = b.order_criteria?.[productCodeB]?.time1;

        const entryDateTimeA = `${entryDateA} ${entryTimeA}`;
        const entryDateTimeB = `${entryDateB} ${entryTimeB}`;

        if (!entryTimeA && !entryTimeB) {
          return productCodeA.localeCompare(productCodeB);
        } else if (!entryTimeA) {
          return 1;
        } else if (!entryTimeB) {
          return -1;
        } else {
          return new Date(entryDateTimeA).getTime() - new Date(entryDateTimeB).getTime();
        }
      });
    },
  },

  methods: {
    getBTicketProps(item: ReceiptItem) {
      return {
        ticketData: item,
        orderReference: this.orderReference,
        subItems: this.subItems,
        showDirections: this.itemIsParkingProduct(item),
        showPolicies: true,
        isEntryExitProduct: this.itemIsParkingProduct(item),
        ...(!this.itemIsParkingProduct(item) && {
          hideLocationInfo: this.modifications?.hideLocationInfo,
          hideAccessSection: this.modifications?.hideAccessSection,
          accessInfoFromEntranceInfo:
            this.modifications?.accessInfoFromEntranceInfo,
        }),
        collapsible: true,
      };
    },
    itemIsParkingProduct(item: ReceiptItem): boolean {
      return item.product_code === 'parking';
    },
  },
});
