
import { defineComponent } from 'vue'
import type VueI18n from 'vue-i18n'

const KEY_BOOKING = 'KEY_BOOKING' as const
const KEY_MY_PROFILE = 'KEY_MY_PROFILE' as const
const KEY_BOOKING_SEARCH = 'KEY_BOOKING_SEARCH' as const

type PathKey = typeof KEY_BOOKING | typeof KEY_MY_PROFILE | typeof KEY_BOOKING_SEARCH;

interface LinkTab {
  key: PathKey;
  text: string | VueI18n.TranslateResult;
  path: string;
  isExternal: boolean;
}

interface PathMap {
  KEY_BOOKING: string;
  KEY_MY_PROFILE: string;
  KEY_BOOKING_SEARCH: string;
}

export default defineComponent({
  name: 'NavbarTabs',

  props: {
    bookingLink: {
      type: String,
      default: ''
    },

    myProfileLink: {
      type: String,
      default: ''
    },

    bookingSearchLink: {
      type: String,
      default: ''
    }
  },

  computed: {
    tabs (): LinkTab[] {
      const tabs: Array<LinkTab> = []

      if (this.bookingLink) {
        tabs.push({
          key: KEY_BOOKING,
          text: this.$t('UI.tabs.bookings'),
          path: this.getPath(KEY_BOOKING),
          isExternal: this.checkIsExternal(KEY_BOOKING)
        })
      }

      if (this.myProfileLink) {
        tabs.push({
          key: KEY_MY_PROFILE,
          text: this.$t('UI.tabs.myProfile'),
          path: this.getPath(KEY_MY_PROFILE),
          isExternal: this.checkIsExternal(KEY_MY_PROFILE)
        })
      }

      if (this.bookingSearchLink) {
        tabs.push({
          key: KEY_BOOKING_SEARCH,
          text: this.$t('UI.tabs.myBookings'),
          path: this.getPath(KEY_BOOKING_SEARCH),
          isExternal: this.checkIsExternal(KEY_BOOKING_SEARCH)
        })
      }

      return tabs
    },

    displayTabs (): string {
      return this.bookingLink || this.myProfileLink || this.bookingSearchLink
    },

    getRouteType (): string {
      const routerName = this.$route.name

      if (routerName === 'index') {
        return KEY_BOOKING
      }

      if (routerName === 'my-profile') {
        return KEY_MY_PROFILE
      }

      return ''
    },

    areTabsDark (): string {
      return this.bookingSearchLink
    },

    pathMap(): PathMap {
      return {
        KEY_BOOKING: this.bookingLink,
        KEY_MY_PROFILE: this.myProfileLink,
        KEY_BOOKING_SEARCH: this.bookingSearchLink
      }
    }
  },

  methods: {
    getPath (key: PathKey): string {
      return this.pathMap[key]
    },
    checkIsExternal(key: PathKey): boolean {
      const path = this.pathMap[key]
      return path.includes('http');
    }
  }
})
