
import { PropType, defineComponent } from 'vue';
import { LocaleMessage } from 'vue-i18n';

import CavuButton from '../cavu-button/cavu-button.vue';

export type DesktopMenuData = {
  nickname: string;
  logoutHandler: () => void;
  makeNewBookingHandler: () => void;
};

export default defineComponent({
  name: 'UserMenuDesktop',

  components: {
    CavuButton,
    IconPlusCircle: () => import('@white-label-icon/icon-plus-circle'),
    IconUserProfile: () => import('@white-label-icon/icon-user-profile'),
  },

  props: {
    menuData: {
      type: Object as PropType<DesktopMenuData>,
      required: true,
    },
  },

  data() {
    return {
      isOpened: false,
    };
  },

  computed: {
    hideBookingButtons() {
      return this.$launchDarkly?.variation(
        'PREM-856-Aether-Hide-Booking-Buttons'
      );
    },
    isMenuExist(): boolean {
      return !!this.menuData?.nickname;
    },

    nickname(): unknown {
      return this.menuData?.nickname;
    },

    nameAbbreviation(): string {
      if (this.$loggedUser?.given_name && this.$loggedUser?.family_name) {
        const firstInitial = this.$loggedUser.given_name.substring(0, 1);
        const lastInitial = this.$loggedUser.family_name.substring(0, 1);
        return `${firstInitial}${lastInitial}`.toUpperCase();
      }

      let nameAbbreviation = '';

      if (typeof this.nickname === 'string') {
        nameAbbreviation = this.nickname
          .split(' ')
          .map((word) => word[0].toUpperCase())
          .join('');
      }

      return nameAbbreviation;
    },

    logoutText(): LocaleMessage {
      return this.$t('UI.buttons.logOut');
    },

    makeNewBookingText(): LocaleMessage {
      return this.$t('UI.buttons.makeNewBooking');
    },
  },

  methods: {
    close() {
      this.isOpened = false;
    },
  },
});
