
import { defineComponent } from 'vue';

// Mixins
import auth from '@white-label-helper/mixin-auth';
import BackButton from '../back-button/back-button.vue';
import BookingsPreloader from '../bookings-preloader/bookings-preloader.vue';
import {
  BOOKING_STATUSES,
  NAMED_ROUTES,
} from '@white-label-configuration/constants';
import { ManageBookingState } from '@white-label-types/stores';
import {
  readBooking,
  readInventoryItem,
  readManageBookingItems,
  readId,
} from '@white-label-store/manage-booking';
import { TranslateResult } from 'vue-i18n';
import { DATE_TIME_FORMATS, format } from '@white-label-helper/date-utilities';
import { OrderItem } from '@white-label-types/account-booking';
import BookingDetailsCard from '../booking-details-card/booking-details-card.vue';
import { capitalize } from 'lodash';
import { PiiDataGuest } from '@white-label-types/parking-booking';
import ControlButton from '../button/button.vue';
import {
  getBookingBaggageDetails,
  postQuestionnaire,
} from '@white-label-helper/api-manage-booking';

export default defineComponent({
  name: 'BookingGuestsInfo',
  components: {
    ControlButton,
    IconAlert: () => import('@white-label-icon/icon-alert'),
    BookingDetailsCard,
    BookingsPreloader,
    BackButton,
  },
  mixins: [auth],
  data() {
    return {
      baggageDetailsStatus: BOOKING_STATUSES.incomplete,
    };
  },
  computed: {
    getId() {
      const id = this.$cookies.get('manageBookingToken')?.id;
      return id || readId(this.$store);
    },
    order(): ManageBookingState['manageBookingState'] {
      return readBooking(this.$store);
    },
    inventoryItem() {
      return !this.getId
        ? readInventoryItem(this.$store)
        : this.manageBookingItems;
    },
    manageBookingItems() {
      return (
        readManageBookingItems(this.$store).find(
          (booking) => booking.id === this.getId
        ) || {}
      ).inventory_item;
    },
    piiData(): ManageBookingState['manageBookingState']['piiData'] {
      return this.order.piiData;
    },
    isInboundProduct(): boolean {
      return this.inventoryItem?.direction === 'inbound';
    },
    flightNumber(): string {
      const flight = this.isInboundProduct
        ? this.order.user?.inbound_flight
        : this.order.user?.outbound_flight;

      return flight || '-';
    },
    leadGuestData() {
      return {
        title: `${this.$t('guestDetails.guest')} 1`,
        subtitle: `${this.piiData.guests[0].first_name} ${this.piiData.guests[0].last_name}`,
        piiToken: this.piiData.guests[0].id,
        status: this.piiData.guests[0].status,
      };
    },
    guestsCards() {
      if (this.piiData.guests.length === 1) {
        return [];
      }
      const slicedGuests = this.piiData.guests.slice(1);

      const groupedData = slicedGuests.reduce((acc, obj) => {
        const key = obj.group;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

      const uniqueGroups = Object.keys(groupedData);
      const renderedData = [];
      let totalGuests = 2;
      uniqueGroups.forEach((group) => {
        groupedData[group].forEach((item: PiiDataGuest) => {
          renderedData.push({
            title: `${this.$t('guestDetails.guest')} ${totalGuests++}`,
            subtitle: capitalize(item.group),
            piiToken: item.id,
            status: item.status,
            guestType: group
          });
        });
      });

      const adult = renderedData.filter(el => el.guestType === 'adult')
      const senior = renderedData.filter(el => el.guestType === 'senior')
      const child = renderedData.filter(el => el.guestType === 'child')
      const infant = renderedData.filter(el => el.guestType === 'infant')

      const orderedArray = [...adult,...senior,...child,...infant];

      return orderedArray;
    },
    allowDetailsSubmission() {
      return (
        this.piiData.guests.every(
          ({ status }) => status === BOOKING_STATUSES.complete
        ) && this.baggageDetailsStatus === BOOKING_STATUSES.complete
      );
    },
    getManagementToken() {
      const token = this.$cookies.get('manageBookingToken')?.token;

      return token;
    },
  },
  async mounted() {
    const bookingBaggageDetailsData = await getBookingBaggageDetails(
      this.getToken,
      this.getManagementToken,
      this.getId
    );

    if (bookingBaggageDetailsData) {
      this.baggageDetailsStatus = BOOKING_STATUSES.complete;
    }
  },
  methods: {
    goToBookingHome() {
      this.$router.push(NAMED_ROUTES.booking_app.home);
    },

    getFlightTime(flightDateTime: string): TranslateResult | string {
      if (!flightDateTime) {
        return '';
      }
      const flightTime = format(
        new Date(flightDateTime),
        DATE_TIME_FORMATS.day_month_year_day_time_am_pm_lower_case
      );

      return this.$t('UI.productSummaryCard.info.flightTime', { flightTime });
    },

    getGuestsInfo(guests: OrderItem['groups']): string {
      const guestsInfo = [];
      for (const group of Object.values(guests)) {
        guestsInfo.push(group);
      }
      return guestsInfo.join(', ');
    },
    goToBaggageDetailsPage() {
      this.$router.push(NAMED_ROUTES.booking_baggage_details.home);
    },
    goToQuestionnairePage(piiToken: string) {
      this.$cookies.set('guestPiiData', {
        piiToken: piiToken,
        orderId: this.getId,
      });
      this.$router.push(NAMED_ROUTES.booking_guest_details.questionnaire);
    },
    submitPiiQuestionnaire() {
      if (!this.allowDetailsSubmission) return;

      postQuestionnaire(this.getToken, {
        order_item_id: this.getId,
        vault: 'booking-post-sales',
      }).then(() => {
        this.goToBookingHome();
      });
    },
  },
});
