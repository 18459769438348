import { render, staticRenderFns } from "./booking-guests-info.vue?vue&type=template&id=0651ab91&scoped=true"
import script from "./booking-guests-info.vue?vue&type=script&lang=ts"
export * from "./booking-guests-info.vue?vue&type=script&lang=ts"
import style0 from "./booking-guests-info.vue?vue&type=style&index=0&id=0651ab91&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0651ab91",
  null
  
)

export default component.exports