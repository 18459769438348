import { render, staticRenderFns } from "./navbar-tabs.vue?vue&type=template&id=a136e9c6&scoped=true"
import script from "./navbar-tabs.vue?vue&type=script&lang=ts"
export * from "./navbar-tabs.vue?vue&type=script&lang=ts"
import style0 from "./navbar-tabs.vue?vue&type=style&index=0&id=a136e9c6&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a136e9c6",
  null
  
)

export default component.exports