var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['booking-item', `booking-item--${_vm.booking.status}`]},[(_vm.isPrivateTerminalBooking && !_vm.singleProduct)?_c('div',{staticClass:"booking-item__header"},[_vm._v("\n    Booking ID: "+_vm._s(_vm.booking.id)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"booking-item__top"},[(!_vm.isPrivateTerminalBooking && !_vm.singleProduct)?_c('div',{staticClass:"booking-item__date-status-wrapper"},[_c('div',{staticClass:"booking-item__dates"},[_c('span',{staticClass:"hide-on-desktop",attrs:{"data-testid":"booking-item-date-mobile"}},[_vm._v("\n          "+_vm._s(_vm.formattedDatesMobile)+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"hide-on-mobile",attrs:{"data-testid":"booking-item-date-desktop"}},[_vm._v("\n          "+_vm._s(_vm.formattedDatesDesktop)+"\n        ")])]),_vm._v(" "),(_vm.displayBookingStatus)?_c('BookingStatus',{attrs:{"status":_vm.bookingStatus,"small":_vm.displaySmallStatus,"is-m-a":true}}):_vm._e()],1):_vm._e(),_vm._v(" "),_vm._l((_vm.booking.orderItems),function(orderItem){return _c('div',{key:orderItem.id,staticClass:"order-item"},[_c('p',{staticClass:"booking-item__title",class:{ 'booking-item__title--private': _vm.isPrivateTerminalBooking },attrs:{"data-testid":"booking-item-title"}},[_vm._v("\n        "+_vm._s(orderItem.displayName)+"\n      ")]),_vm._v(" "),(!_vm.isPrivateTerminalBooking && !_vm.singleProduct)?_c('p',{staticClass:"booking-item__address",attrs:{"data-testid":"booking-item-address"}},[_vm._v("\n        "+_vm._s(orderItem.address)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.isPrivateTerminalBooking)?_c('div',{attrs:{"data-testid":"private-lounge-features"}},[_c('p',{staticClass:"booking-item__tag"},[_vm._v("\n          "+_vm._s(_vm.$t(
              `UI.productSummaryCard.tags.privateLounge.${orderItem.direction}`
            ))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"booking-item__flight-time"},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('UI.productSummaryCard.info.captions.flightTime')))]),_vm._v(" "),_c('span',{staticClass:"flight-time",attrs:{"data-testid":"flight-time"}},[_vm._v("\n            "+_vm._s(_vm.getFlightTime(
                _vm.isOrderItemInbound(orderItem.direction)
                  ? orderItem.startedAt.datetime
                  : orderItem.closedAt.datetime
              ))+"\n          ")])]),_vm._v(" "),_c('p',{staticClass:"booking-item__guests",attrs:{"data-testid":"booking-item-guests"}},[_vm._v("\n          "+_vm._s(_vm.getGuestsInfo(orderItem.groups))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"booking-item__features"},[_vm._v("\n          "+_vm._s(_vm.getOrderItemFeatures(orderItem))+"\n        ")])]):_vm._e()])})],2),_vm._v(" "),_c('div',{staticClass:"booking-item__bottom"},[(_vm.loadingManageBooking)?_c('DotsLoader'):[(_vm.isPrivateTerminalBooking)?_c('ButtonControl',{staticClass:"booking-item__button",attrs:{"label":_vm.$t(
            `bookings.bookingItem.${
              _vm.singleProduct ? 'addGuestDetails' : 'enterGuestDetails'
            }`
          ),"type-of-element":"button","button-type":"primary"},on:{"btnClick":_vm.goToGuestsDetails}},[_vm._v("\n        "+_vm._s(_vm.$t(
            `bookings.bookingItem.${
              _vm.singleProduct ? 'addGuestDetails' : 'enterGuestDetails'
            }`
          ))+"\n      ")]):_vm._e(),_vm._v(" "),(!_vm.singleProduct)?_c('TextButton',{staticClass:"booking-item__view-booking-button",attrs:{"data-testid":"booking-item-view-booking-button"},on:{"click":_vm.goToBooking}},[_vm._v("\n        "+_vm._s(_vm.$t('bookings.bookingItem.viewBooking'))+"\n      ")]):_vm._e()]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }